import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | Kodlama Dünyasını Keşfedin
			</title>
			<meta name={"description"} content={"Kodlama Yolculuğunuzda Derinlere Dalın ve Kendi Dijital Masalınızı Yazın"} />
			<meta property={"og:title"} content={"About Us | Kodlama Dünyasını Keşfedin"} />
			<meta property={"og:description"} content={"Kodlama Yolculuğunuzda Derinlere Dalın ve Kendi Dijital Masalınızı Yazın"} />
			<meta property={"og:image"} content={"https://jolixup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://jolixup.com/img/1.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="980px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Biz Kimiz?
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Kodlama Dünyası, herkesin teknolojiyle iç içe bir gelecek kurabilmesi için tasarlanmış online bir eğitim platformudur. Misyonumuz, kodlama becerilerini her yaştan ve her seviyeden öğrenciye ulaştırarak, onları dijital dünyanın sınırsız imkanlarıyla tanıştırmaktır. Gerçek hayattan örneklerle dolu derslerimiz, öğrencilerin teorik bilgileri pratik projelerle pekiştirmelerini sağlar.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://jolixup.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Neden Kodlama Dünyası?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Kodlama Dünyası'nda öğrenim, sadece bir kursu tamamlamak değil, aynı zamanda bir topluluğun parçası olmak demektir. Öğrencilerimiz, kurslarımız aracılığıyla sadece kodlama becerilerini değil, aynı zamanda problem çözme, proje yönetimi ve takım çalışması gibi yaşam boyu kullanacakları becerileri de kazanırlar.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Öne Çıkan Özelliklerimiz
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Gerçek Projeler Üzerinde Çalışma: Derslerimiz, piyasadaki gerçek ihtiyaçlara yönelik projelerle donatılmıştır, böylece öğrendiklerinizi anında uygulama fırsatı bulursunuz.
					<br/><br/>
Esnek ve Erişilebilir Eğitim: İstediğiniz yerden, istediğiniz zaman derslere erişim sağlayarak kendi öğrenme temponuzu kendiniz belirleyebilirsiniz.
					<br/><br/>
Destekleyici Bir Topluluk: Sorularınız ve projeleriniz üzerinde çalışırken size destek olacak geniş bir öğrenci ve eğitmen topluluğuna erişim.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://jolixup.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://jolixup.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Bizimle Kendi Hikayenizi Yazın
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Kodlama Dünyası'nda öğrenim yolculuğunuz sadece kendinizi geliştirmekle kalmaz, aynı zamanda geleceğin teknolojisine yön verecek projelerde yer alma fırsatı bulursunuz. Bizimle birlikte, sadece bir öğrenci değil, teknolojinin şekillendirilmesinde aktif bir rol oynayan bir birey olursunuz.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});